import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

import Wysiwyg from '@components/Layout/Wysiwyg'

import { FeatureProps } from '@contracts/content'

import * as styles from './Feature.module.scss'

const Feature = (props: FeatureProps) => {
	const featureClasses = [styles.feature]

	if (props.className) {
		featureClasses.push(props.className)
	}

	let image

	if (props.image) {
		featureClasses.push(styles.split)

		image = (
			<GatsbyImage
				image={props.image}
				alt={props.imageAlt ? props.imageAlt : ''}
				loading={props.eager ? 'eager' : 'lazy'}
			/>
		)
	} else {
		if (props.imageBkp) {
			featureClasses.push(styles.split)

			image = <img src={props.imageBkp} alt={props.imageAlt} />
		}
	}

	return (
		<div className={featureClasses.join(' ')}>
			<div className={styles.left}>
				{props.pageTitle ? (
					<h1 className={styles.title}>{props.title}</h1>
				) : (
					<h2 className={styles.title}>{props.title}</h2>
				)}
				{props.content && <Wysiwyg className={styles.content} content={props.content} />}
			</div>
			{image && <div className={styles.image}>{image}</div>}
		</div>
	)
}

export default Feature
