import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'

import SocialIcons from '@components/Content/SocialIcons/SocialIcons'

import { ContactDetailsQueryProps } from '@contracts/contact'

import * as styles from './ContactDetails.module.scss'

import astro from '@images/astro/contact-page.png'
import phone from '@images/contact-page/call.svg'
import email from '@images/contact-page/email.svg'

interface Props {
	className?: string
}

const ContactDetails = (props: Props) => {
	const data: ContactDetailsQueryProps = useStaticQuery(graphql`
		query ContactDetailsQuery {
			wp {
				themeSettings {
					themeOptions {
						email
						phoneNumber
						address {
							content
						}
					}
				}
			}
		}
	`)

	const { themeOptions } = data.wp.themeSettings

	const contactDetailsClasses = [styles.cards]

	if (props.className) {
		contactDetailsClasses.push(props.className)
	}

	return (
		<ul className={contactDetailsClasses.join(' ')}>
			<li className={styles.card}>
				<div className={styles.content}>
					<img src={phone} alt="" className={styles.icon} />
					<h2 className={styles.title}>Call us</h2>
					<p className={styles.subtext}>
						<a href={`tel:${themeOptions.phoneNumber.replace(' ', '')}`}>{themeOptions.phoneNumber}</a>
					</p>
				</div>
			</li>
			<li className={styles.card}>
				<div className={styles.content}>
					<img src={email} alt="" className={styles.icon} />
					<h2 className={styles.title}>Email</h2>
					<p className={styles.subtext}>
						<a href={`mailto:${themeOptions.email}`}>{themeOptions.email}</a>
					</p>
				</div>
			</li>
			<li className={styles.card}>
				<div className={styles.content}>
					<SocialIcons iconSet="pink" className={styles.socialIcons} />
					<h2 className={styles.title}>We are on Social</h2>
					<p className={styles.subtext}>Follow us</p>
				</div>
				<img src={astro} alt="" className={styles.astro} />
			</li>
		</ul>
	)
}

export default ContactDetails
