import React from 'react'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import * as buttonStyles from '@components/Content/Button/Button.module.scss'

import * as styles from './ContactForm.module.scss'

const SITE_KEY = process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY
const SECRET_KEY = process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_SECRET

interface CF7Fields {
	field: string
	message: string
}

interface CF7Response {
	contact_form_id: number
	into: string
	invalid_fields: CF7Fields[]
	message: string
	status: 'mail_sent' | 'validation_failed'
}

const Form = () => {
	const [isLoading, setIsLoading] = React.useState(false)
	const [invaliedFields, setInvaliedFields] = React.useState<CF7Fields[]>([])
	const [success, setSuccess] = React.useState(false)
	const [message, setMessage] = React.useState<boolean | string>(false)

	const formRef = React.useRef<HTMLFormElement>(null)

	const InputWrapper = (props: { type: 'text' | 'email' | 'tel' | 'textarea'; name: string; label: string }) => {
		const isInvalid = invaliedFields.find((field) => field.field === props.name)

		const inputStyles = [styles.inputWrapper]

		if (isInvalid) {
			inputStyles.push(styles.error)
		}

		if (props.type !== 'textarea') {
			return (
				<div className={inputStyles.join(' ')}>
					<label htmlFor={props.name} className={styles.label}>
						{props.label}
						<span className={styles.required}>*</span>
					</label>
					<input type="text" name={props.name} id={props.name} className={styles.input} />
					{isInvalid && <p>{isInvalid.message}</p>}
				</div>
			)
		}

		return (
			<div className={inputStyles.join(' ')}>
				<label htmlFor={props.name} className={styles.label}>
					{props.label}
					<span className={styles.required}>*</span>
				</label>
				<textarea id={props.name} name={props.name} className={styles.input} rows={5}></textarea>
				{isInvalid && <p>{isInvalid.message}</p>}
			</div>
		)
	}

	const formStyles = [styles.form]

	if (isLoading) {
		formStyles.push(styles.loading)
	}

	const { executeRecaptcha } = useGoogleReCaptcha()

	const handleReCaptchaVerify = React.useCallback(async () => {
		if (!executeRecaptcha) {
			console.log('Execute recaptcha not yet available')
			return false
		}

		return await executeRecaptcha('contactform')
		// Do whatever you want with the token
	}, [executeRecaptcha])

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		if (formRef.current !== null) {
			setIsLoading(true)
			setMessage(false)

			const token = await handleReCaptchaVerify()

			if (!token) {
				setIsLoading(false)
				setMessage("You've somehow submited the form instantly.")
			}

			const { result } = await window
				.fetch('/api/recaptcha', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						secret: SECRET_KEY,
						token: token,
					}),
				})
				.then((res) => res.json())

			if (!result.success) {
				setIsLoading(false)
				setMessage('Something went wrong with the recaptcha.')

				return
			}

			// 5 Is the ID of our form in the database
			const path = process.env.GATSBY_WORDPRESS_URL_PROTOCOL + '://' + process.env.GATSBY_WORDPRESS_URL_PATH
			const url = path + '/wp-json/contact-form-7/v1/contact-forms/5/feedback'

			const formData = new FormData(formRef.current)

			const response = await fetch(url, {
				method: 'POST',
				body: formData,
			})
			const data: CF7Response = await response.json()

			setIsLoading(false)
			setMessage(data.message)
			setInvaliedFields(data.invalid_fields)

			if (data.status === 'mail_sent') {
				setSuccess(true)
				formRef.current?.reset()
			}
		}
	}

	return (
		<form className={formStyles.join(' ')} ref={formRef} onSubmit={handleSubmit}>
			{message && <p>{message}</p>}

			<input type="hidden" name="_wpcf7" value="5" />
			<input type="hidden" name="_wpcf7_version" value="5.5.6" />
			<input type="hidden" name="_wpcf7_locale" value="en_GB" />
			<input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f49221-o1" />
			<input type="hidden" name="_wpcf7_container_post" value="0" />
			<input type="hidden" name="_wpcf7_posted_data_hash" value="" />

			<fieldset>
				{InputWrapper({
					type: 'text',
					name: 'firstname',
					label: 'First name',
				})}

				{InputWrapper({
					type: 'text',
					name: 'lastname',
					label: 'Last name',
				})}

				{InputWrapper({
					type: 'tel',
					name: 'telephone',
					label: 'Telephone',
				})}

				{InputWrapper({
					type: 'email',
					name: 'email',
					label: 'Email',
				})}

				{InputWrapper({
					type: 'textarea',
					name: 'message',
					label: 'Message',
				})}

				<button
					className={[buttonStyles.button, buttonStyles.buttonPink, styles.button].join(' ')}
					disabled={isLoading || success}
				>
					Submit
				</button>
			</fieldset>
		</form>
	)
}

const ContactForm = () => {
	if (SITE_KEY && SECRET_KEY) {
		return (
			<GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
				<Form />
			</GoogleReCaptchaProvider>
		)
	} else {
		return <p>reCAPTCHA key not set</p>
	}
}

export default ContactForm
