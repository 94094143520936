import React from 'react'

import Feature from '@components/Content/Feature/Feature'
import Section from '@components/Content/Section/Section'
import GatsbyHead from '@components/Layout/GatsbyHead'
import Layout from '@components/Layout/Layout'
import SEO from '@components/Layout/SEO'
import ContactDetails from '@components/Pages/ContactPage/ContactDetails'
import ContactForm from '@components/Pages/ContactPage/ContactForm'

import { ContactPageProps } from '@contracts/contact'

import * as styles from './ContactPage.module.scss'

const ContactPage = (props: ContactPageProps) => {
	const { data } = props.pageContext
	const { feature } = data.template.contactPageOptions

	const featuredImageBkp = feature && feature.image && feature.image.mediaItemUrl ? feature.image.mediaItemUrl : ''
	const featuredImage =
		feature &&
		feature.image &&
		feature.image.localFile &&
		feature.image.localFile.childImageSharp &&
		feature.image.localFile.childImageSharp.gatsbyImageData
			? feature.image.localFile.childImageSharp.gatsbyImageData
			: null

	return (
		<Layout>
			<Section noPaddingBottom>
				<Feature
					title={feature.title}
					content={feature.content}
					image={featuredImage}
					imageBkp={featuredImageBkp}
					pageTitle={true}
				></Feature>
			</Section>

			<Section containerClassName={styles.bottomContainer}>
				<ContactDetails className={styles.contactDetails} />
				<ContactForm />
			</Section>
		</Layout>
	)
}

export function Head(props: ContactPageProps) {
	return (
		<>
			<GatsbyHead />
			<SEO data={props.pageContext.data.seo} />
		</>
	)
}

export default ContactPage
